<template>
    <button class="btn btn-toggle-side-bar btn-outline-dark"
            v-on:click="toggleSideBar">
        <font-awesome-icon icon="bars"></font-awesome-icon>
    </button>
</template>

<script>
export default {
    name: "ButtonToggleSideBar",
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {
        toggleSideBar () {
            this.$store.commit("layout/toggleSideBar");
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
